/* TradeTable.css */

.trade-table-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filters-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-header {
  margin-bottom: 10px;
}

.filter-item {
  margin-bottom: 15px;
}

.filter-heading {
  font-size: 1.2em;
  cursor: pointer;
  color: #333;
}

.filter-select {
  width: 100%;
  max-width: 300px;
  margin-top: 5px;
}

.trade-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.trade-table th,
.trade-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.trade-table th {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.trade-table th.sortable {
  user-select: none;
}

.trade-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.trade-table tr.expandable-row:hover {
  background-color: #e6f7ff;
  cursor: pointer;
}

.trade-table tr.profit {
  background-color: #e0ffe0;
}

.trade-table tr.loss {
  background-color: #ffe0e0;
}

.expanded-row {
  background-color: #f9f9f9;
}

.secondary-details {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.secondary-details h4 {
  margin-bottom: 10px;
}

.secondary-table {
  width: 100%;
  border-collapse: collapse;
}

.secondary-table td {
  padding: 5px;
  border: none;
}

.total-profit-loss {
  font-size: 1.5em;
  font-weight: bold;
  text-align: right;
  margin-top: 20px;
  color: #333;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.filter-item {
  flex: 1 1 calc(33.333% - 20px);
  min-width: 250px;
}

@media (max-width: 768px) {
  .filters {
    flex-direction: column;
  }

  .filter-item {
    flex: 1 1 100%;
  }
}

.filter-heading:hover {
  color: #007bff;
}

.trade-table th {
  position: relative;
}

.trade-table th::after {
  content: '\25B2';
  position: absolute;
  right: 10px;
  font-size: 0.8em;
  display: none;
}

.trade-table th.sortable.asc::after {
  content: '\25B2';
  display: inline-block;
}

.trade-table th.sortable.desc::after {
  content: '\25BC';
  display: inline-block;
}
